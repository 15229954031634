interface MatamoWindow extends Window {
  _paq?: Array<any>;
}

const getSiteIdForEnvironment = () => {
  console.log(process.env.isLocal);
  if (process.env.isLocal) {
    return '34';
  } else if (process.env.isDev) {
    return '34';
  } else if (process.env.isTest) {
    return '35';
  } else if (process.env.isStage) {
    return '36';
  } else if (process.env.isProd) {
    return '37';
  }

  return 0;
};

export const matomoEmbed = function (userName: string) {
  let matamoWindow: MatamoWindow = window;

  if (!matamoWindow._paq) {
    const siteId = getSiteIdForEnvironment();
    let _paq = (matamoWindow._paq = matamoWindow._paq || []);
    _paq.push(['setUserId', userName ?? 'USERID_NOT_FOUND']);
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);

    let u = 'https://matomo-ext.devops.amgen.com/';

    _paq.push(['setTrackerUrl', u + 'piwik.php']);
    _paq.push(['setSiteId', siteId]);

    let d = document,
      g = d.createElement('script'),
      s = d.getElementsByTagName('script')[0];

    g.type = 'text/javascript';
    g.async = true;
    g.src = u + 'piwik.js';
    s.parentNode.insertBefore(g, s);
  }
};
