export const getOktaDetails = () => {
  const oktaToken = localStorage.getItem('sensing-okta-token');
  const oktaTokenObj = JSON.parse(oktaToken);
  if (oktaTokenObj && oktaTokenObj.accessToken !== undefined) {
    return oktaTokenObj;
  }
  return null;
};

export const getOktaToken = () => {
  const oktaTokenDetails = getOktaDetails();
  if (oktaTokenDetails !== null) {
    return oktaTokenDetails.accessToken.accessToken;
  }
  return '';
};

export const openApplications = [
  '@amgen/rtsensing-root-config',
  '@amgen/rtsensing-auth',
];

export const isAuthorized = (authMappingConstants: any) => {
  if (!authMappingConstants) {
    return false;
  }
  const routeSlice = window.location.pathname.split('/');
  const route = routeSlice[1] == '' ? 'home' : routeSlice[1];
  if (authMappingConstants[route]) {
    return true;
  }
  return false;
};

export const isApplicationAuthorized = (
  authMappingConstants: any,
  applicationName: string,
) => {
  const mapping = {
    '@amgen/rtsensing-home': 'home',
    '@amgen/rtsensing-external': 'external',
    '@amgen/rtsensing-pipeline': 'pipeline',
    '@amgen/rtsensing-supply': 'supply',
    '@amgen/rtsensing-finance': 'finance',
    '@amgen/rtsensing-people': 'people',
    '@amgen/rtsensing-brand': 'brand',
    '@amgen/rtsensing-priortized-agenda': 'prioritizedagenda',
    '@amgen/rtsensing-notification': 'notification',
    '@amgen/rtsensing-notifications-subscriptions': 'notificationsSubscriptions',
  };

  if (!authMappingConstants) {
    return false;
  }
  if (mapping[applicationName]) {
    return true;
  }
  return false;
};
